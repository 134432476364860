import React from 'react';
import PageMeta from '../components/PageMeta';

const Custom404 = () => {
  return (
    <>
      <PageMeta title="Not found" />
      <h1>404 - Page Not found</h1>
    </>
  );
};

export default Custom404;
